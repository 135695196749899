import React, { Component } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import img2 from "./62cde2f8ac2f40dd6f34fdd6606585e3.png"

import img4 from "./107ec0f81279d8e2c918dfcbe35f9576.png"

import img6 from "./c9a5f662bcd3501bfaea6fbe42efecfb.png"
import style from "./Slider.scss"
import {motion} from "framer-motion";


export default class AutoPlay extends Component {
    render() {
        const SAnimation ={
            hidden:{
                y: 100,
                opacity: 0,
            },
            visible: custom => ({
                y: 0,
                opacity: 1,
                transition:{ delay: custom * 0.1},
            }),
        }

        const settings = {
            dots: true,
            infinite: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            autoplay: true,
            speed: 4000,
            autoplaySpeed: 2000,
            cssEase: "linear"
        };
        return (
            <motion.div initial={"hidden"}
                         whileInView={"visible"}
                         viewport={{amount: 0.1, once:true}}>
                <Slider {...settings} >
                    <motion.div  custom={1}
                                 variants={SAnimation}>
                        <img src={img2}/>
                    </motion.div>
                    <motion.div  custom={2}
                                 variants={SAnimation}>
                        <img src={img4}/>
                    </motion.div>
                    <motion.div  custom={3}
                                 variants={SAnimation}>
                        <img src={img6}/>
                    </motion.div>
                </Slider>
            </motion.div>
        );
    }
}