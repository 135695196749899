import style from "./Gasolines.module.scss";
import {Btn} from "../../../../../common/components/Btn/Btn";
import gasoline from "../../../../../common/icons/icons-gas-station.png"
import {motion} from "framer-motion";
import {forwardRef, useState} from "react";
import {Modal} from "../../../../../common/components/Modal/Modal";

export const Gasolines = forwardRef (({props},ref) => {
    const [modalActive, setModalActive] = useState(false)
    return (
            <div ref={ref} className={style.oilProduct}>
                <div className={style.listBox}>
                    <img className={style.icon} src={gasoline}/>
                    <span className={style.title}>
                        Бензины
                    </span>
                    <span className={style.description}>
                        <p>АИ80</p>
                        <p>АИ92</p>
                        <p>АИ95</p>
                        <p>АИ98</p>
                        <p>АИ100</p>
                    </span>
                    <div className={style.btn}>
                        <Btn title={'Сделать заказ'} onClick={()=>setModalActive(true)}/>
                    </div>
                    <Modal active={modalActive} setActive={setModalActive}/>
                </div>
            </div>
    );
});
export const MGasolines = motion(Gasolines)