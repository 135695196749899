import style from './Main.module.scss'
import oilImg from "../../common/intro_bg.png";
import {motion} from "framer-motion";




export const Main = ({marginTop}) =>{

    const mainAnimation ={
        hidden:{
            y: 100,
            opacity: 0,
        },
        visible: custom => ({
            y: 0,
            opacity: 1,
            transition:{ delay: custom * 0.9},
        }),
    }

    return(
        <motion.div className={style.mainBlock}
                    initial={"hidden"}
                    whileInView={"visible"}
                    viewport={{amount: 0.2, once:true}}
                    style={{marginTop}}
        >
            <div className={style.imgBlock}>
                    <motion.img custom={1}
                                variants={mainAnimation}
                                src={oilImg}
                                className={style.mainImg}/>
                    <motion.h2 custom={2}
                               variants={mainAnimation}
                               className={style.text}
                    >Надежность и результат.<br />Для каждого.</motion.h2>
            </div>
        </motion.div>
    )
};
