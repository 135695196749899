import style from "./Header.module.scss"
import logo from "../../common/logo/logo_.png";
import iconCall from "../../common/icons/icons8-iphone.png"
import iconLocation from "../../common/icons/icon-location.png"
import {motion, useTransform} from "framer-motion";
import {Link} from "react-scroll"


export const Header = ({offsetY, scrollY}) => {

    const heightSizes = [200, 80];
    const imgSizes = [200, 80];
    const imgPadding = [50, 3];






    const height = useTransform(scrollY, offsetY, heightSizes);
    const imgWidth = useTransform(scrollY, offsetY, imgSizes);

    const paddingLogo = useTransform(scrollY, offsetY, imgPadding);



    return (
        <motion.header id={"header"}
            className={style.header}
            style={{height}}
        >
            <div className={style.container}>
                <motion.img
                    src={logo}
                    className={style.logo}
                style={{
                    width: imgWidth,
                    padding: paddingLogo,
                }}
                />
                <motion.div className={style.location}
                           >
                    <div className={style.icon}>
                        <img src={iconLocation}
                             className={style.iconLocation}/>
                    </div>
                    <div className={style.l}>
                        sale@oilsintez.ru
                    </div>

                </motion.div>
                <motion.div className={style.number}>

                    <div className={style.icon}>
                        <img src={iconCall}
                             className={style.iconCall}/>
                    </div>
                    <div className={style.n}>
                        +7 913-633-90-91
                    </div>

                </motion.div>
                <Link
                    activeClass={style.active}
                    to="contacts"
                    spy={true}
                    smooth={true}
                    offset={1}
                    duration={500}
                >  Оставить заявку
                </Link>
            </div>
        </motion.header>
    )
}