import style from "./Diesel.module.scss";
import diesel from "../../../../../common/icons/icon-petrol.png"
import {Btn} from "../../../../../common/components/Btn/Btn";
import {motion} from "framer-motion";
import {forwardRef, useState} from "react";
import {Modal} from "../../../../../common/components/Modal/Modal";

export const Diesel = forwardRef(({props}, ref) => {

    const [modalActive, setModalActive] = useState(false)
    return (
        <div ref={ref} className={style.oilProduct}>
            <div className={style.listBox}>
                <img className={style.icon} src={diesel}/>
                <span className={style.title}>
                      Дизельное топливо
                    </span>
                <span className={style.description}>
                      <p>ДТ-E-K сорт F</p>
                      <p>ДТ-E-K сорт E</p>
                      <p>ДТ-3-K5</p>
                      <p>ДТ-3-K5 минус 32</p>
                      <p>ДТ-Л-К5</p>
                      <p>ДТ-Л-K5=Л-55</p>
                      <p>ДТ-Л-K5 Сорт С</p>
                      <p>ДТ марки A</p>
                      <p>ДТ марки Б</p>
                    </span>
                <div className={style.btn}>
                    <Btn title={'Сделать заказ'} onClick={()=>setModalActive(true)}/>
                </div>
                <Modal active={modalActive} setActive={setModalActive}/>
            </div>
        </div>
    );
});
export const MDiesel = motion(Diesel)