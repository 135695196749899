import style from "./Dark.module.scss";
import dark from "../../../../../common/icons/icon-oil-tanker.png"
import {Btn} from "../../../../../common/components/Btn/Btn";
import {motion} from "framer-motion";
import {forwardRef, useState} from "react";
import {Modal} from "../../../../../common/components/Modal/Modal";

export const Dark = forwardRef(({props}, ref) => {



    const [modalActive, setModalActive] = useState(false)

    return (
        <div ref={ref} className={style.oilProduct}>
            <div className={style.listBox}>
                <img className={style.icon} src={dark}/>
                <span className={style.title}>
                      Темные нефтепродукты
                    </span>
                <span className={style.description}>
                    <p>   Битум БНД 60-90</p>
                    <p> Битум БНД 100-130</p>
                    <p>  Мазут М-100</p>
                    <p> Мазут ТКМ-16</p>
                    </span>
                <div className={style.btn}>
                    <Btn title={'Сделать заказ'} onClick={()=>setModalActive(true)}/>
                </div>
                <Modal active={modalActive} setActive={setModalActive}/>
            </div>
        </div>
    );
});
export const MDark = motion(Dark)