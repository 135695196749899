import style from "./Contacts.module.scss"
import {Form, MForm} from "../../Form/Form";
import logoCompany from "../../../common/logo/logo_inversion.png"
import {motion} from "framer-motion";

export function Contacts() {
    const CAnimation ={
        hidden:{
            y: 100,
            opacity: 0,
        },
        visible: custom => ({
            y: 0,
            opacity: 1,
            transition:{ delay: custom * 0.2},
        }),
    }

    return (
        <motion.div id={"contacts"}
                    className={style.contactsBlock}
                    initial={"hidden"}
                    whileInView={"visible"}
                    viewport={{amount: 0.2, once:true}}>
            <motion.h2 className={style.title}
                       custom={1}
                       variants={CAnimation}>Контакты</motion.h2>
            <div className={style.container}>
                <div className={style.itemsWrap}>
                    <motion.img src={logoCompany}
                                alt="logoCompany"
                                className={style.logoDescription}
                                custom={2}
                                variants={CAnimation}/>
                    <motion.div className={style.itemCall}
                                custom={3}
                                variants={CAnimation}>
                        <a href={"tel: +7 913-633-90-91"}>
                            <span className={style.iconPhone}/>
                            <span className={style.phone}>+7 913-633-90-91</span>
                        </a>
                    </motion.div>
                    <motion.div className={style.itemMail}
                                custom={4}
                                variants={CAnimation}>
                        <a href={"mailto:sale@oilsintez.ru"}>
                            <span className={style.iconEmail}/>
                            <span className={style.email}>sale@oilsintez.ru</span>
                        </a>
                    </motion.div>
                    <motion.div className={style.itemAddress}
                                custom={5}
                                variants={CAnimation}>
                        <a href={"map:Омск, ул. 1-я Заводская, д. 23, каб. 16"}>
                            <span className={style.iconAddress}/>
                            <span className={style.address}>Россия, г.Омск, ул. 1-я Заводская, д. 23, каб. 16</span>
                        </a>
                    </motion.div>
                    <div className={style.icons}>
                        <span className={style.whatsApp}/>
                        <span className={style.telegram}/>
                        <span className={style.call}/>
                    </div>
                </div>
                <MForm custom={6}
                       variants={CAnimation}/>
            </div>
        </motion.div>
    )
}
