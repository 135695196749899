import style from "./Delivery.module.scss"
import {NavLink} from "react-router-dom";
import carry from "../../../../common/icons/icons8-pickup-point.png"
import rt from "../../../../common/icons/icons8-train.png"
import at from "../../../../common/icons/icons8-semi-truck.png"
import {motion} from "framer-motion";
import {forwardRef} from "react";


export const Delivery = forwardRef(({title},ref) => {
    const DAnimation ={
        hidden:{
            y: 100,
            opacity: 0,
        },
        visible: custom => ({
            y: 0,
            opacity: 1,
            transition:{ delay: custom * 0.2},
        }),
    }
    return (
        <motion.div ref={ref} className={style.deliveryBlock}
             initial={"hidden"}
             whileInView={"visible"}
             viewport={{amount: 0.9, once:true}}>
            <h3 className={style.title}>{title}</h3>
            <div className={style.DeliveryNavBox}>
                <motion.div className={style.rt}
                            custom={1}
                            variants={DAnimation}>
                    <NavLink to={"rt"}
                             style={({isActive})=>({color: isActive ? '#0eb0ee' : '#fff' })}
                             className={style.active}>
                        <div className={style.iconBox}>
                            <img src={rt} className={style.icon}/>
                        </div>
                        Железнодорожным транспортом
                    </NavLink>
                </motion.div>
                  <motion.div className={style.at}
                              custom={2}
                              variants={DAnimation}>
                      <NavLink  to={"at"}
                                style={({isActive})=>({color: isActive ? '#0eb0ee' : '#fff' })}
                                className={style.active}>
                          <div className={style.iconBox}>
                              <img src={at} className={style.icon}/>
                          </div>
                          Автомобильным транспортом
                      </NavLink>
                  </motion.div>
                <motion.div className={style.carry}
                     custom={3}
                     variants={DAnimation}>
                    <NavLink to={"/carry"}
                             style={({isActive})=>({color: isActive ? '#0eb0ee' : '#fff' })}
                             className={style.active}>
                        <div className={style.iconBox}>
                            <img src={carry} className={style.icon}/>
                        </div>
                        Самовывоз с нефтебаз
                    </NavLink>
                </motion.div>
            </div>
        </motion.div>
    )
})

export const MDelivery = motion(Delivery)