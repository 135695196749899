import style from "./Title.module.scss"
import {motion} from "framer-motion";
import {forwardRef} from "react";


export const Title = forwardRef (({title},ref) =>{
    return(
        <h2 ref={ref} className={style.title}>{title}</h2>
        )



})
export const MTitle = motion(Title);