import style from "./AT.module.scss"
import imgAt from "../../../common/icons/tab02.jpeg"


export function AT(){
    return(
        <div className={style.atBlock}>
            <div className={style.listBox}>
                <img className={style.icon} src={imgAt}/>
                <span className={style.decriptionBlock}>
                    <span className={style.decription}>
                  Сотрудничество с сертифицированными нефтебазами позволяет осуществлять поставку нефтепродуктов по всем существующим стандартам качества с соответствующими арбитражными пробами специализированным автомобильным транспортом. Круглогодично и круглосуточно предоставляем полный сервис автопоставок топлива по территории Иркутской области, Красноярскому краю, Амурской области, Алтайскому краю, Республике Саха (Якутия) и др.
                    </span>
                </span>
            </div>
        </div>
    )
}