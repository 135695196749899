import style from "./OilProducts.module.scss"
import {MTitle} from "../../../common/components/Title/Title";
import {MGasolines} from "./OilProduct/Gasolines/Gasolines";
import {MDark} from "./OilProduct/Dark/Dark";
import {MDiesel} from "./OilProduct/Dielsel/Diesel";
import {motion} from "framer-motion";

export function OilProducts() {
    const OPAnimation ={
        hidden:{
            y: 100,
            opacity: 0,
        },
        visible: custom => ({
            y: 0,
            opacity: 1,
            transition:{ delay: custom * 0.1},
        }),
    }

    return (
        <motion.div id={"oilProducts"}
                    className={style.oilProductsBlock}
                    initial={"hidden"}
                    whileInView={"visible"}
                    viewport={{amount: 0.2, once:true}}>
            <div className={style.container}>
                <MTitle title={'Нефтепродукты'}
                        custom={1}
                        variants={OPAnimation}/>
                <div className={style.oilProducts}>
                    <MGasolines  custom={2}
                                 variants={OPAnimation}/>
                    <MDark custom={4}
                           variants={OPAnimation}/>
                    <MDiesel custom={6}
                             variants={OPAnimation}/>
                </div>
            </div>
        </motion.div>
    )
}