import style from "./Map.module.scss"
import {MTitle} from "../../../common/components/Title/Title";
import map from "./map1.png"
import {motion} from "framer-motion";
export function Map(){
    const MAnimation ={
        hidden:{
            y: 100,
            opacity: 0,
        },
        visible: custom => ({
            y: 0,
            opacity: 1,
            transition:{ delay: custom * 0.1},
        }),
    }
    return(
        <motion.div id={"map"}
                    className={style.mapBlock}
                    initial={"hidden"}
                    whileInView={"visible"}
                    viewport={{amount: 0.2, once:true}}>
            <div className={style.container}>
                <MTitle title={'Карта поставок'}
                        custom={1}
                        variants={MAnimation}/>
                <motion.div className={style.listBox}
                            custom={2}
                            variants={MAnimation}>
                    <div className={style.text}>
                        <p>— Омская область</p>
                        <p>— Тюменская область</p>
                        <p>— Новосибирская область</p>
                        <p>— Алтайский край</p>
                        <p>— Красноярский край</p>
                        <p>— Томская область</p>
                        <p>— Кемеровская область</p>
                        <p>— Иркутская область</p>
                        <p>— Республика Бурятия</p>
                        <p>— Республика Саха (Якутия)</p>
                        <p>— Забайкальский край</p>
                        <p>— Амурская область</p>
                        <p>— ХМАО</p>
                        <p>— ЯНАО</p>
                        <p>и другие.</p>
                    </div>
                </motion.div>
            </div>
        </motion.div>
    )
}