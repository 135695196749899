import style from "./Partners.module.css"
import {Title} from "../../../common/components/Title/Title";

import AutoPlay from "./Slider";

export function Partners(){

    return(
        <div id={"partners"}
            className={style.PartnersBlock}>
            <Title title={'Поставщики'}/>
            <div className={style.container}>
                <div className={style.innerWrap}>
                  <AutoPlay/>
                </div>
            </div>
        </div>
    )
}