import style from "./RT.module.scss"
import imgRt from "../../../common/icons/zd.jpeg"

export function RT(props) {
    return (
        <div className={style.rtBlock}>
            <div className={style.listBox}>
                <img className={style.icon} src={imgRt}/>
                <span className={style.decriptionBlock}>
                    <span className={style.description}>
                    Поставка осуществляется партиями в цистернах парка ОАО “Российские железные дороги”,
                        в Омскую область,
                        Тюменскую область,
                        Новосибирскую область,
                        Алтайский край,
                        Красноярский край,
                        Томскую область,
                        Кемеровскую область,
                        Иркутскуюобласть,
                        Республику Бурятия,
                        Республику Саха (Якутия),
                        Забайкальский край,
                        Амурскую область,
                        ХМАО,
                        ЯНАО
                        и другие.
                    </span>
                    </span>
            </div>
        </div>
    )
}