import s from "./Modal.css"
import {useState} from "react";
import {Btn} from "../Btn/Btn";
import {Form} from "../../../components/Form/Form";


export const Modal = ({active, setActive}) => {
    return (
        <div className={active ? "modal active" : "modal"} onClick={()=> setActive(false)}>
            <div onClick={e => e.stopPropagation()}>
                <Form/>
            </div>

        </div>
    )

}

