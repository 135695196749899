import style from "./Deliveries.module.scss"
import {MDelivery} from "./Delivery/Delivery";
import {Route, Routes} from "react-router-dom";
import {RT} from "./RT";
import {AT} from "./AT";
import {Carry} from "./Carry";
import {motion} from "framer-motion";




export function Deliveries(){
    const DelAnimation ={
        hidden:{
            y: 100,
            opacity: 0,
        },
        visible: custom => ({
            y: 0,
            opacity: 1,
            transition:{ delay: custom * 0.1},
        }),
    }
    return(
        <motion.div id={"deliveries"}
                    className={style.deliveriesBlock}
                    initial={"hidden"}
                    whileInView={"visible"}
                    viewport={{amount: 0.2, once:true}}>
            <div className={style.container}>
                <motion.h2 className={style.title}
                           custom={1}
                           variants={DelAnimation}>Доставка</motion.h2>
                <div className={style.deliveries}>
                    <MDelivery  custom={2}
                                variants={DelAnimation}/>
                    <Routes>
                        <Route path={'/RT'} element={<RT/>}/>
                        <Route path={'/AT'} element={<AT/>}/>
                        <Route path={'/Carry'} element={<Carry/>}/>
                    </Routes>
                </div>
            </div>
        </motion.div>
    )

}