import style from "./AboutUs.module.scss"
import {MTitle} from "../../../common/components/Title/Title";
import imgOffer from "../../../common/icons/icon-briefcase.png"
import imgTeam from "../../../common/icons/icon-business-group.png"
import imgDelivery from "../../../common/icons/icon-map-marker.png"
import imgLogistic from "../../../common/icons/icon-compass.png"
import {motion} from "framer-motion";

export function AboutUs(){
    const AboutAnimation ={
        hidden:{
            y: 100,
            opacity: 0,
        },
        visible: custom => ({
            y: 0,
            opacity: 1,
            transition:{ delay: custom * 0.1},
        }),
    }
    return(
        <motion.div id={"about"}
                    className={style.AboutUsBlock}
                    initial={"hidden"}
                    whileInView={"visible"}
                    viewport={{amount: 0.9, once:true}}>
            <MTitle custom={1}
                    variants={AboutAnimation}
                    title={'О Компании'}/>
         <div className={style.container}>
             <motion.div className={style.a}
                         custom={2}  variants={AboutAnimation}>
                 <motion.span  custom={3}  variants={AboutAnimation} className={style.textA}>
                     ООО «Нефтесинтез» профильная региональная компания, обладающая ключевыми компетенциями в сфере оборота нефтепродуктов и газа.
                     Создана командой профессионалов,
                     обладающих опытом работы на рынке реализации нефтепродуктов более 15 лет.
                     Опираясь на безупречную деловую репутацию, имеет долгосрочные многолетние контракты со всеми основными поставщиками продукта.
                     Оперируя актуальной рыночной конъюнктурой, используя современные методы биржевой деятельности,
                     является поставщиком для большого числа потребителей реального сектора экономики на всей территории Российской Федерации и за её пределами.
                     Имея возможности хранения и налива на основных нефтебазах страны, доставки всеми имеющимися видами транспорта,
                     обеспечивает надёжную и удобную логистику поставок, транспарентные ценовые предложения, гибкие условия расчётов.
                 </motion.span>
                 <motion.span  custom={4}  variants={AboutAnimation} className={style.textB}>За годы безупречной деятельности мы завоевали доверие клиентов и партнеров со всей России.
                       Мы всегда добросовестно выполняем условия по поставкам, деловая репутация ООО «Нефтесинтез» безупречна.
                       Заключив договор на поставку нефтепродуктов с нашей компанией, вы получите честного, надежного и проверенного временем партнера!
                       Залогом этому служит коллектив профессионалов, а также тесные связи с поставщиками — крупнейшими Российскими нефтяными и нефтехимическими компаниями,
                       среди которых «Роснефть», «Лукойл», «Газпромнефть».

                 </motion.span>
                 <div className={style.itemsWrap}>
                     <motion.div className={style.team}
                                 custom={5}
                                 variants={AboutAnimation}
                     >
                         <div className={style.item}>
                             <div className={style.centerImg}>
                                 <img className={style.iconTeam} src={imgTeam}/>
                             </div>
                             <div className={style.title}>
                                 Команда
                                 <br/>
                                 профессионалов
                             </div>
                         </div>
                     </motion.div>
                     <motion.div className={style.logistics}
                                 custom={6}
                                 variants={AboutAnimation}
                     >
                         <div className={style.item}>
                             <div className={style.centerImg}>
                                 <img className={style.iconLogistic} src={imgLogistic}/>
                             </div>
                             <div className={style.title}>
                                 Надёжная логистика
                                 <br/>
                                 поставок
                             </div>
                         </div>
                     </motion.div>
                     <motion.div className={style.offer}
                                 custom={7}
                                 variants={AboutAnimation}
                     >
                         <div className={style.item}>
                             <div className={style.centerImg}>
                                 <img className={style.iconOffer} src={imgOffer}/>
                             </div>
                             <div className={style.title}>
                                 Транспарентные
                                 <br/>
                                 ценовые предложения
                                 <br/>
                                 и гибкие условия
                                 <br/>
                                 поставок
                             </div>
                         </div>
                     </motion.div>
                     <motion.div className={style.delivery}
                                 custom={8}
                                 variants={AboutAnimation}
                     >
                         <div className={style.item}>
                             <div className={style.centerImg}>
                                 <img className={style.iconDelivery} src={imgDelivery}/>
                             </div>
                             <div className={style.title}>
                                 Доставка топлива
                                 <br/>
                                 в любую точку РФ
                             </div>
                         </div>
                     </motion.div>
                 </div>

             </motion.div>
         </div>
        </motion.div>
    )
}