import './App.css'
import {Header} from "./components/Header/Header";
import {AboutUs} from "./components/Pages/AboutUs/AboutUs";
import {OilProducts} from "./components/Pages/OilProducts/OilProducts";
import {Deliveries} from "./components/Pages/Deliveries/Deliveries";
import {Map} from "./components/Pages/Map/Map";
import {Partners} from "./components/Pages/Partners/Partners";
import {Contacts} from "./components/Pages/Contacts/Contacts";
import {useTransform, motion, useViewportScroll} from "framer-motion";
import {Main} from "./components/Main/Main";
import {Nav} from "./components/Nav/Nav";


function App() {

    const {scrollY} = useViewportScroll();
    const offsetY = [0, 300];
    const marginTop = useTransform(scrollY, offsetY, offsetY)

    return (
        <div className='App'>
            <Header offsetY={offsetY}
                    scrollY={scrollY}
                   />
            <Main marginTop={marginTop} />
            <Nav offsetY={offsetY}
                 scrollY={scrollY}/>
            <AboutUs    />
            <OilProducts/>
            <Deliveries/>
            <Map/>
            <Partners/>
            <Contacts/>
        </div>
    );
}
export default App;
