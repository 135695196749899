import style from "./Carry.module.scss"
import oilCarry from "../../../common/icons/img-carry.jpeg"


export function Carry(){
    return(
        <div className={style.carryBlock}>
            <div className={style.listBox}>
                <img className={style.imgOilCarry} src={oilCarry}/>
                <span className={style.decriptionBlock}>
                    <span className={style.decription}>
                    Поставка осуществляется партиями в цистернах парка ОАО “Российские железные дороги”,
                        в Омская область,
                        Тюменская область,
                        Новосибирская область,
                        Алтайский край,
                        Красноярский край,
                        Томская область,
                        Кемеровская область,
                        Иркутская область,
                        Республика Бурятия,
                        Республика Саха (Якутия),
                        Забайкальский край,
                        Амурская область,
                        ХМАО,
                        ЯНАО
                        и другие.
                    </span>
                    </span>
            </div>
        </div>
    )
}


