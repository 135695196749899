import style from "./Nav.module.scss"
import {motion, useTransform} from "framer-motion";
import {Link} from "react-scroll"

export const Nav = ({offsetY, scrollY}) =>{


    const heightSizes = [100, 50];



    const height = useTransform(scrollY, offsetY, heightSizes);


    return(
        <motion.div className={style.nav}
                    style={{height, position:"sticky", top:"46px"}}
        >
            <Link
                activeClass={style.active}
                to="about"
                spy={true}
                smooth={true}
                offset={-300}
                duration={500}
            >О Компании</Link>
            <Link
                activeClass={style.active}
                to="oilProducts"
                spy={true}
                smooth={true}
                offset={50}
                duration={500}
            >Нефтепродукты</Link>
            <Link
                activeClass={style.active}
                to="deliveries"
                spy={true}
                smooth={true}
                offset={5}
                duration={500}
            >Доставка</Link>
            <Link
                activeClass={style.active}
                to="map"
                spy={true}
                smooth={true}
                offset={1}
                duration={500}
            >Карта поставок</Link>
            <Link
                activeClass={style.active}
                to="partners"
                spy={true}
                smooth={true}
                offset={1}
                duration={500}
            >Наши партнеры</Link>
             <Link
                activeClass={style.active}
                to="contacts"
                spy={true}
                smooth={true}
                offset={1}
                duration={500}
            >Контакты</Link>
        </motion.div>
    )
}