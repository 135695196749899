import style from "./Form.module.css";
import {useEffect, useState} from "react";
import {motion} from "framer-motion";
import {forwardRef} from "react";

export const Form = forwardRef(({props},ref) => {

    const [name, setName] = useState('')
    const [phone, setPhone] = useState('')
    const [email, setEmail] = useState('')

    const [nameDirty, setNameDirty] = useState(false)
    const [phoneDirty, setPhoneDirty] = useState(false)
    const [emailDirty, setEmailDirty] = useState(false)

    const [nameError, setNameError] = useState('Поле обязательно.')
    const [phoneError, setPhoneError] = useState('Поле обязательно.')
    const [emailError, setEmailError] = useState('Поле обязательно.')


    const [formValid, setFormValid] = useState(false)


    const blurHandler = (e) => {
        switch (e.target.name) {
            case 'name':
                setNameDirty(true)
                break
            case 'phone':
                setPhoneDirty(true)
                break
            case 'email':
                setEmailDirty(true)
                break
        }
    }
    const emailHandler = (e) => {
        setEmail(e.target.value)
        const re = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
        if (!re.test(String(e.target.value).toLowerCase())) {
            setEmailError('error')
        } else {
            setEmailError('')
        }
    }
    const nameHandler = (e) => {
        setName(e.target.value)
        const re = /^[а-яА-Я ]+$/;
        if (!re.test(String(e.target.value).toLowerCase())) {
            setNameError('error')
        } else {
            setNameError('')
        }
    }


    const phoneHandler = (e) => {
        setPhone(e.target.value)
        const re = /^[+]*[(]{0,1}[0-9]{1,3}[)]{0,1}[-\s\./0-9]*$/g
        if (!re.test(String(e.target.value).toLowerCase())) {
            setPhoneError('error')
        } else {
            setPhoneError('')
        }
    }


    useEffect(() => {
        if (nameError || phoneError || emailError) {
            setFormValid(false)
        } else {
            setFormValid(true)
        }

    }, [nameError, phoneError, emailError])


    return (
        <div ref={ref} className={style.formWrapper}>
            <div className={style.form}>
                <form action="#" id={"form"} className={style.formBody}>
                    <div className={style.formItem}>
                        <label form={"formName"} className={style.formLabel}>

                        </label>
                        {(nameDirty && nameError) && <div className={style.error}>{nameError}</div>}
                        <input placeholder={'Ваше имя'}
                               value={name}
                               onChange={e => nameHandler(e)}
                               onBlur={e => blurHandler(e)}
                               type="text"
                               id={"formName"}
                               name={"name"}
                               className={style.formInput}/>

                    </div>
                    <div className={style.formItem}>
                        <label form={"formPhone"} className={style.formPhone}>
                        </label>
                        {(phoneDirty && phoneError) && <div className={style.error}>{phoneError}</div>}
                        <input placeholder={'Ваш телефон'}
                               value={phone}
                               onChange={e => phoneHandler(e)}
                               onBlur={e => blurHandler(e)}
                               type="text"
                               id={"formPhone"}
                               name={"phone"}
                               className={style.formInput}/>
                    </div>
                    <div className={style.formItem}>
                        <label form={"formEmail"} className={style.formEmail}>

                        </label>
                        {(emailDirty && emailError) && <div className={style.error}>{emailError}</div>}
                        <input onChange={e =>
                            emailHandler(e)}
                               placeholder={'Ваш E-mail'}
                               value={email}
                               onBlur={e => blurHandler(e)}
                               type="text"
                               id={"formEmail"}
                               name={"email"}
                               className={style.formInput}/>
                    </div>
                    <div className={style.formItem}>
                        <div className={style.checkbox}>
                            <input id={"formAgreement"}
                                   checked
                                   type="checkbox"
                                   name={"agreement"}
                                   className={style.checkboxInput}/>
                            <label form={"formAgreement"} className={style.checkboxLabel}>
                            <span className={style.text}
                            > Я даю свое согласие на обработку персональных данных в соответствии с <a
                                href="">Условиями</a>*</span>
                            </label>
                        </div>
                    </div>
                    <button
                        disabled={!formValid}
                        type={"submit"}
                        className={style.formBtn}
                    >Отправить
                    </button>
                </form>
            </div>
        </div>
    )
})
export const MForm = motion(Form)